/* eslint-disable @typescript-eslint/no-var-requires */
import { ReactComponent as Add } from 'assets/images/icon/add.svg';
import { ReactComponent as Alert } from 'assets/images/icon/alert.svg';
import { ReactComponent as ArrowDown } from 'assets/images/icon/arrow-down.svg';
import { ReactComponent as ArrowBlueUp } from 'assets/images/icon/arrow-blue-up.svg';
import { ReactComponent as BatteryStations } from 'assets/images/icon/battery-stations.svg';
import { ReactComponent as Battery } from 'assets/images/icon/battery.svg';
import { ReactComponent as BrokenBattery } from 'assets/images/icon/broken_battery.svg';
import { ReactComponent as Check } from 'assets/images/icon/check.svg';
import { ReactComponent as Close } from 'assets/images/icon/close.svg';
import { ReactComponent as Delete } from 'assets/images/icon/delete.svg';
import { ReactComponent as DoubleArrow } from 'assets/images/icon/double-arrow.svg';
import { ReactComponent as Edit } from 'assets/images/icon/edit.svg';
import { ReactComponent as Email } from 'assets/images/icon/email.svg';
import { ReactComponent as Female } from 'assets/images/icon/female.svg';
import { ReactComponent as Flag } from 'assets/images/icon/flag.svg';
import { ReactComponent as Image } from 'assets/images/icon/image.svg';
import { ReactComponent as LeftBack } from 'assets/images/icon/left-back.svg';
import { ReactComponent as Location } from 'assets/images/icon/location.svg';
import { ReactComponent as Location_2 } from 'assets/images/icon/location_v2.svg';
import { ReactComponent as Logout } from 'assets/images/icon/logout.svg';
import { ReactComponent as LongArrow } from 'assets/images/icon/long-arrow.svg';
import { ReactComponent as Male } from 'assets/images/icon/male.svg';
import { ReactComponent as Menu } from 'assets/images/icon/menu.svg';
import { ReactComponent as More } from 'assets/images/icon/more.svg';
import { ReactComponent as MultiUsers } from 'assets/images/icon/multi-users.svg';
import { ReactComponent as Notification } from 'assets/images/icon/notification.svg';
import { ReactComponent as Phone } from 'assets/images/icon/phone.svg';
import { ReactComponent as PinLocation } from 'assets/images/icon/pin-location.svg';
import { ReactComponent as Profile } from 'assets/images/icon/profile.svg';
import { ReactComponent as Question } from 'assets/images/icon/question.svg';
import { ReactComponent as Scooter } from 'assets/images/icon/scooter.svg';
import { ReactComponent as Search } from 'assets/images/icon/search.svg';
import { ReactComponent as Settings } from 'assets/images/icon/settings.svg';
import { ReactComponent as SingleArrow } from 'assets/images/icon/single-arrow.svg';
import { ReactComponent as Speed } from 'assets/images/icon/speed.svg';
import { ReactComponent as Stats } from 'assets/images/icon/stats.svg';
import { ReactComponent as Subs } from 'assets/images/icon/subs.svg';
import { ReactComponent as Subscription } from 'assets/images/icon/subscription.svg';
import { ReactComponent as CustomerService } from 'assets/images/icon/customer_service.svg';
import { ReactComponent as UploadPhoto } from 'assets/images/icon/upload-photo.svg';

export default {
	logo: require('assets/images/savart/logo.png').default,
	logo_text: require('assets/images/savart/logo_text.png').default,
	bgLogin: require('assets/images/background/bg-login.png').default,
	bgLoginMobile: require('assets/images/background/bg-login-mobile.png').default,

	loadingLightGrey: require('assets/images/loading/spinner-lightgrey.gif').default,
	loadingBlue: require('assets/images/loading/spinner-blue.gif').default,
	loadingBlack: require('assets/images/loading/spinner-black.gif').default,

	icons: {
		add: Add,
		alert: Alert,
		arrowDown: ArrowDown,
		arrowBlueUp: ArrowBlueUp,
		batteryStations: BatteryStations,
		battery: Battery,
		brokenBattery: BrokenBattery,
		check: Check,
		close: Close,
		delete: Delete,
		doubleArrow: DoubleArrow,
		edit: Edit,
		email: Email,
		female: Female,
		flag: Flag,
		image: Image,
		leftBack: LeftBack,
		location: Location,
		location_2: Location_2,
		locked: require('assets/images/icon/locked.png').default,
		logout: Logout,
		long_arrow: LongArrow,
		male: Male,
		menu: Menu,
		more: More,
		multiUsers: MultiUsers,
		notif: Notification,
		phone: Phone,
		pinLocation: PinLocation,
		pin: require('assets/images/icon/pin.png').default,
		profile: Profile,
		question: Question,
		scooter: Scooter,
		search: Search,
		settings: Settings,
		singleArrow: SingleArrow,
		speed: Speed,
		stats: Stats,
		subs: Subs,
		subscription: Subscription,
		store: require('assets/images/icon/store.png').default,
		scooterPoint: require('assets/images/icon/scooter-point-png.png').default,
		thief: require('assets/images/icon/thief.png').default,
		customerService: CustomerService,
		uploadPhoto: UploadPhoto
	},

	maps: {
		marker_icon_2x: require('assets/images/maps/pin_4.png').default,
		marker_icon: require('assets/images/maps/pin_2.png').default,
		marker_scooter_2x: require('assets/images/maps/pin_scooter_4.png').default,
		marker_scooter: require('assets/images/maps/pin_scooter_2.png').default,
		marker_shadow: require('assets/images/maps/marker_shadow.png').default
	},

	emptyState: {
		batteryStations: require('assets/images/empty_state/battery_stations.png').default,
		scooter: require('assets/images/empty_state/scooter.png').default,
		chart: require('assets/images/empty_state/chart.png').default,
		mileage: require('assets/images/empty_state/mileage.png').default,
		users: require('assets/images/empty_state/users.png').default,
		location: require('assets/images/empty_state/location.png').default,
	},

	state: {
		success: require('assets/images/state/success.png').default,
		warning: require('assets/images/state/warning.png').default
	},

	avatar: require('assets/images/dummy/avatars.png').default,
	user1: require('assets/images/dummy/user.png').default,
	user2: require('assets/images/dummy/user2.png').default,
	user3: require('assets/images/dummy/user3.png').default,
	ktp: require('assets/images/dummy/ktp.png').default,
	stnk: require('assets/images/dummy/stnk.png').default,
	selfie: require('assets/images/dummy/selfie.png').default,
	scooter1: require('assets/images/dummy/scooter1.png').default,
	scooter2: require('assets/images/dummy/scooter2.png').default,
	scooter3: require('assets/images/dummy/scooter3.png').default,
	battery: require('assets/images/dummy/battery.png').default,
	currentLocMaps: require('assets/images/dummy/current-loc-maps.png').default,

	pairingSuccess: require('assets/images/loading/pairing-success.png').default,
	pairingFailed: require('assets/images/loading/pairing-failed.png').default,
};
