import React, { useEffect, useState } from 'react';
import { Icon, Image, Modal, Text } from 'components';
import { hooks, screen } from 'helpers';
import { ModalReportStyle } from '../ModalReport/style';
import { Colors, Images } from 'consts';
import Lottie from 'react-lottie';
import { postPairingKeyless } from 'services/scooter';
import pairAnimation from 'assets/images/loading/pairing.json';

type ModalPairKeylessProps = {
  vin: number,
  visible: boolean;
  onCloseModal: () => void;
};

export default function ModalPairKeyless({
  vin,
  visible,
  onCloseModal
}: ModalPairKeylessProps) {
  const windowDimensions: hooks.Dimensions = hooks.useWindowDimensions();
  const isMobile = windowDimensions.width <= screen.isMobile;
  const [isPairing, setIsPairing] = useState(false);
  const [isPairingSuccess, setIsPairingSuccess] = useState(false);
  const [pairingError, setPairingError] = useState('');

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: pairAnimation,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
  };

  const pairKeyless = async() => {
    try {
      setIsPairing(true);
      const response = await postPairingKeyless(vin);
      if (response.stat_code.includes('ERR')) {
        throw new Error(response.stat_msg);
      }
      setIsPairingSuccess(true);
    } catch (error: any) {
      setPairingError(error.message);
    } finally {
      setIsPairing(false);
    }
  };

  useEffect(() => {
    if (visible && !isPairing) {
      pairKeyless();
    }
  }, [visible]);

  useEffect(() => {
    if (visible && (isPairingSuccess || Boolean(pairingError))) {
      setTimeout(() => {
        onCloseModal();
        setPairingError('');
        setIsPairingSuccess(false);
        setIsPairing(false);
      }, 2000);
    }
  }, [
    isPairingSuccess,
    pairingError,
    visible
  ]);

  return (
    <Modal
      visible={ visible }
      onCloseModal={ () => {
        onCloseModal();
        setIsPairingSuccess(false);
      } }
      modalType='long-scroll'
      padding={ 30 }
      bodyStyle={ {
        marginTop: 50,
        marginBottom: 30,
      } }
      width={ 500 }
    >
      <ModalReportStyle>
        <div className='col justify-align-center mb1'>
          { isPairing && (
            <Lottie
              options={ defaultOptions }
              height={ 250 }
              width={ 250 }
            />
          ) }
          { !isPairing && isPairingSuccess && (
            <Image width={ 250 } height={ 250 } src={ Images.pairingSuccess } />
          ) }
          { !isPairing && pairingError && (
            <Image width={ 250 } height={ 250 } src={ Images.pairingFailed } />
          ) }
        </div>
        <div>
          <div className='col center-content mb8'>
            <Text
              size='l'
              weight={ 700 }
              align='center'
              mt={ 23 }
              color={ Colors.black.isTextBlack3 }
            >{ pairingError ? 'Pairing Failed' : isPairingSuccess ? 'Pairing Success' : 'Pairing Keyless...' }</Text>
            <Text
              size='m'
              color={ Colors.grey.isDarkGrey }
              align='center'
              mt={ 20 }
              style={ { textTransform: 'capitalize' } }
            >{ pairingError ? pairingError :  isPairingSuccess ? `Keyless Successfully Paired with VIN ${vin}` : 'Waiting for Keyless Response to be Paired' }</Text>
          </div>
        </div>
      </ModalReportStyle>
    </Modal>
  );
}
